import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api"
    }}>{`API`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hooks"
        }}>{`Hooks`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#useapidata"
            }}><inlineCode parentName="a">{`useApiData()`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#useactions"
            }}><inlineCode parentName="a">{`useActions()`}</inlineCode></a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#config"
        }}>{`Config`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configureapidata"
            }}><inlineCode parentName="a">{`configureApiData()`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#userequesthandler"
            }}><inlineCode parentName="a">{`useRequestHandler()`}</inlineCode></a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#redux-actions"
        }}>{`Redux Actions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#afterrehydrate"
            }}><inlineCode parentName="a">{`afterRehydrate()`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#performapirequest-deprecated"
            }}><inlineCode parentName="a">{`performApiRequest()`}</inlineCode>{` (Deprecated)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#invalidateapidatarequest-deprecated"
            }}><inlineCode parentName="a">{`invalidateApiDataRequest()`}</inlineCode>{` (Deprecated)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#selectors"
        }}>{`Selectors`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getentity"
            }}><inlineCode parentName="a">{`getEntity()`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getapidatarequest-deprecated"
            }}><inlineCode parentName="a">{`getApiDataRequest()`}</inlineCode>{` (Deprecated)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#getresultdata-deprecated"
            }}><inlineCode parentName="a">{`getResultData()`}</inlineCode>{` (Deprecated)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hoc"
        }}>{`HOC`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#withapidata"
            }}><inlineCode parentName="a">{`withApiData()`}</inlineCode></a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#types-and-interfaces"
        }}>{`Types and interfaces`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#networkstatus"
            }}><inlineCode parentName="a">{`NetworkStatus`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidatabinding"
            }}><inlineCode parentName="a">{`ApiDataBinding`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#actions"
            }}><inlineCode parentName="a">{`Actions`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidatarequest"
            }}><inlineCode parentName="a">{`ApiDataRequest`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#endpointparams"
            }}><inlineCode parentName="a">{`EndpointParams`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidataendpointconfig"
            }}><inlineCode parentName="a">{`ApiDataEndpointConfig`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidataglobalconfig"
            }}><inlineCode parentName="a">{`ApiDataGlobalConfig`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidataconfigbeforeprops"
            }}><inlineCode parentName="a">{`ApiDataConfigBeforeProps`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidataconfigafterprops"
            }}><inlineCode parentName="a">{`ApiDataConfigAfterProps`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#apidatastate"
            }}><inlineCode parentName="a">{`ApiDataState`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#requesthandler"
            }}><inlineCode parentName="a">{`RequestHandler`}</inlineCode></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#handledresponse"
            }}><inlineCode parentName="a">{`HandledResponse`}</inlineCode></a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "hooks"
    }}>{`Hooks`}</h2>
    <h3 {...{
      "id": "useapidata"
    }}><inlineCode parentName="h3">{`useApiData()`}</inlineCode></h3>
    <p>{`Get an `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{` for the given endpoint. It will make sure data gets (re-)loaded if needed. This behavior is
based on the `}<inlineCode parentName="p">{`autoTrigger`}</inlineCode>{` and `}<inlineCode parentName="p">{`cacheDuration`}</inlineCode>{` settings in `}<a parentName="p" {...{
        "href": "#apidataendpointconfig"
      }}>{`EndpointConfig`}</a>{` and will, by default, trigger
the call when the endpoint's method is `}<inlineCode parentName="p">{`GET`}</inlineCode>{` and the call has not yet been triggered before. This makes it possible to use
this hook for the same call in multiple components, without needing to worry about which components needs to trigger the call
and how to share the data between the components. Just "use" the API data in multiple components and the fetching of data will be handled.
In any case it will return an `}<inlineCode parentName="p">{`ApiDataBinding`}</inlineCode>{` that reflects the current state of the `}<em parentName="p">{`API call`}</em>{`, identified by the combination
of the `}<em parentName="p">{`endpointKey`}</em>{` and the `}<em parentName="p">{`params`}</em>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`params?`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ApiDataBinding`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidatabinding"
          }}>{`ApiDataBinding`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import { useApiData } from 'react-api-data';

const Article = (props) => {
   const article = useApiData('getArticle', { id: props.articleId });
   return (
       <>
           {article.request.networkStatus === 'success' && 
               <div>
                   <h1>{article.data.title}</h1>
                   <p>{article.data.body}</p>
               </div>
           }
       </>
   );
}
`}</code></pre>
    <h3 {...{
      "id": "useactions"
    }}><inlineCode parentName="h3">{`useActions()`}</inlineCode></h3>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <p><em parentName="p">{`none`}</em></p>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Actions`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#actions"
          }}>{`Actions`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`   const actions = useActions()
   // Do a perform on any endpoint configured.
   actions.perform('postArticle', {id: article.id}, {body: 'The body of my article'})
   // Invalidate the cache on any endpoint configured.
   actions.invalidateCache('getArticles');
   // purge the whole apiData store (invalidate all)
   actions.purgeAll()
           
`}</code></pre>
    <h2 {...{
      "id": "config"
    }}>{`Config`}</h2>
    <h3 {...{
      "id": "configureapidata"
    }}><inlineCode parentName="h3">{`configureApiData()`}</inlineCode></h3>
    <p>{`Register your global and endpoint configurations. Make sure you do this before you mount any components using
withApiData.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`globalConfig`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidataglobalconfig"
          }}>{`ApiDataGlobalConfig`}</a></strong>{` - `}<inlineCode parentName="li">{`endpointConfig`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidataendpointconfig"
          }}>{`ApiDataEndpointConfig`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p>{` `}<strong parentName="p">{`Object`}</strong>{` Redux action`}</p>
    <hr></hr>
    <h3 {...{
      "id": "userequesthandler"
    }}><inlineCode parentName="h3">{`useRequestHandler()`}</inlineCode></h3>
    <p>{`Use your own request function that calls the api and reads the responseBody response. Make sure it implements the
`}<a parentName="p" {...{
        "href": "#requesthandler"
      }}>{`RequestHandler`}</a>{` interface.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`requestHandler`}</inlineCode>{` `}<strong parentName="li">{`RequestHandler`}</strong></li>
    </ul>
    <h2 {...{
      "id": "redux-actions"
    }}>{`Redux Actions`}</h2>
    <h3 {...{
      "id": "afterrehydrate"
    }}><inlineCode parentName="h3">{`afterRehydrate()`}</inlineCode></h3>
    <p>{`Call this after you've re-hydrated the store when using redux-persist or any other method of persisting and restoring
the entire apiData state. This is needed to reset loading statuses.`}</p>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Object`}</strong>{` Redux action to dispatch`}</p>
    <hr></hr>
    <h3 {...{
      "id": "performapirequest-deprecated"
    }}><inlineCode parentName="h3">{`performApiRequest()`}</inlineCode>{` (Deprecated)`}</h3>
    <p>{`Manually trigger an request to an endpoint. Primarily used for any non-GET requests. For GET requests it is preferred
to use `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`withApiData`}</a>{`.`}</p>
    <p><strong parentName="p">{`Deprecated`}</strong></p>
    <p>{`The performApiRequest Action has been deprecated. It is recommended to use the perform action in the `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{` or the `}<a parentName="p" {...{
        "href": "#apidataaction"
      }}>{`ApiDataAction`}</a>{` perform, which is returned by the `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`HOC`}</a>{` in the apiDataActions prop, and in the `}<a parentName="p" {...{
        "href": "#ApiDataEndpointConfig"
      }}>{`afterSuccess`}</a>{` and `}<a parentName="p" {...{
        "href": "#ApiDataEndpointConfig"
      }}>{`afterFailed`}</a>{` events in the `}<a parentName="p" {...{
        "href": "##ApiDataEndpointConfig"
      }}>{`endpoint configuration`}</a>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`params?`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`body?`}</inlineCode>{` `}<strong parentName="li">{`any`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`instanceId?`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Object`}</strong>{` Redux action to dispatch. Dispatching this returns: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
        }}>{`Promise`}</a>{`<`}<a parentName="strong" {...{
          "href": "#apidatabinding"
        }}>{`ApiDataBinding`}</a>{`>`}</strong>{` Rejects when endpointKey is unknown. Otherwise resolves with ApiDataBinding after call has completed. Use request networkStatus to see if call was succeeded or not.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "invalidateapidatarequest-deprecated"
    }}><inlineCode parentName="h3">{`invalidateApiDataRequest()`}</inlineCode>{` (Deprecated)`}</h3>
    <p>{`Invalidates the result of a request, settings it's status back to 'ready'. Use for example after a POST, to invalidate
a GET list request, which might need to include the newly created entity.`}</p>
    <p><strong parentName="p">{`Deprecated`}</strong></p>
    <p>{`The invalidateApiDataRequest Action has been deprecated. It is recommended to use the invalidateCache action in the `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{` or the `}<a parentName="p" {...{
        "href": "#apidataaction"
      }}>{`ApiDataAction`}</a>{` invalidateCache, which is returned by the `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`HOC`}</a>{` in the apiDataActions prop, and in the `}<a parentName="p" {...{
        "href": "#ApiDataEndpointConfig"
      }}>{`afterSuccess`}</a>{` and `}<a parentName="p" {...{
        "href": "#ApiDataEndpointConfig"
      }}>{`afterFailed`}</a>{` events in the `}<a parentName="p" {...{
        "href": "##ApiDataEndpointConfig"
      }}>{`endpoint configuration`}</a>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`params?`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`instanceId?`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Object`}</strong>{` Redux action to dispatch`}</p>
    <h2 {...{
      "id": "selectors"
    }}>{`Selectors`}</h2>
    <h3 {...{
      "id": "getentity"
    }}><inlineCode parentName="h3">{`getEntity()`}</inlineCode></h3>
    <p>{`Selector for getting a single entity from normalized data.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`apiDataState`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidatastate"
          }}>{`ApiDataState`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`schema`}</inlineCode>{` `}<strong parentName="li">{`Object`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`id`}</inlineCode>{` `}<strong parentName="li">{`string | number`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Object | void`}</strong></p>
    <hr></hr>
    <h3 {...{
      "id": "getapidatarequest-deprecated"
    }}><inlineCode parentName="h3">{`getApiDataRequest()`}</inlineCode>{` (Deprecated)`}</h3>
    <p>{`Selector to manually get a `}<a parentName="p" {...{
        "href": "#apidatarequest"
      }}>{`ApiDataRequest`}</a>{`. This value is automatically bind when using `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`withApiData`}</a>{`.
This selector can be useful for tracking request status when a request is triggered manually, like a POST after a
button click.`}</p>
    <p><strong parentName="p">{`Deprecated`}</strong></p>
    <p>{`The getApiDataRequest selector has been deprecated. It is recommended to use the request property returned by the `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{`, which is returned by the `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`HOC`}</a>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`apiDataState`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidatastate"
          }}>{`ApiDataState`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`params`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`instanceId?`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "#apidatarequest"
        }}>{`ApiDataRequest`}</a>{` | void`}</strong></p>
    <hr></hr>
    <h3 {...{
      "id": "getresultdata-deprecated"
    }}><inlineCode parentName="h3">{`getResultData()`}</inlineCode>{` (Deprecated)`}</h3>
    <p>{`Get the de-normalized result data of an endpoint, or undefined if not (yet) available. This value is automatically
bound when using `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`withApiData`}</a>{`. This selector can be useful for getting response responseBody values when a request is
triggered manually, like a POST after a button click.`}</p>
    <p><strong parentName="p">{`Deprecated`}</strong></p>
    <p>{`The getResultData selector has been deprecated. It is recommended to use the request property returned by the `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{`, which is returned by the `}<a parentName="p" {...{
        "href": "#withapidata"
      }}>{`HOC`}</a>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`apiDataState`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidatastate"
          }}>{`ApiDataState`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`params`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`instanceId?`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`any`}</strong></p>
    <h2 {...{
      "id": "hoc"
    }}>{`HOC`}</h2>
    <h3 {...{
      "id": "withapidata"
    }}><inlineCode parentName="h3">{`withApiData()`}</inlineCode></h3>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`withApiData({
   article: 'getArticle',
   users: 'getUser',
   editArticle: 'editArticle' // an endpoint with autoTrigger false
}, (ownProps, state) => ({
   article: {
       id: ownProps.articleId,
   },
   // sometimes you need to call one endpoint multiple times (simultaneously) with different parameter values:
   users: state.users.map(user => ({
       userId: user.id
   })),
   editArticle: {}
}))(MyComponent);
// props.article will be an ApiDataBinding
// props.users will be an array of ApiDataBinding
// props.editArticle will be an ApiDataBinding
// props.apiDataActions will be an Actions object

// perform can be used to trigger calls with autoTrigger: false
props.editArticle.perform({
   id: props.articleId
}, {
   title: 'New Title',
   content: 'New article content'
});

// the apiDataAction property can be used to perform actions on any endpoint in the endpoint config, not only those which are in the current binding.
props.apiDataActions.invalidateCache('getArticles');
`}</code></pre>
    <p>{`Binds api data to component props and automatically triggers loading of data if it hasn't been loaded yet. The wrapped
component will get an `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{` or `}<a parentName="p" {...{
        "href": "#apidatabinding"
      }}>{`ApiDataBinding`}</a>{`[] added to each property key of the bindings param and a property `}<inlineCode parentName="p">{`apiDataActions`}</inlineCode>{` of type `}<a parentName="p" {...{
        "href": "#action"
      }}>{`Action`}</a>{`.`}</p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`bindings`}</inlineCode>{` `}<strong parentName="li">{`{ `}{`[propName in TPropNames]`}{`: string }`}</strong>{` maps prop names to endpoint keys`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`getParams`}</inlineCode>{` `}<strong parentName="li">{`(ownProps: any, state: any) => { `}{`[propName in TPropName]`}{`?: EndpointParams | EndpointParams[] }`}</strong>{` optionally provide the URL parameters. Providing an `}<inlineCode parentName="li">{`EndpointParams[]`}</inlineCode>{` for a binding results in an `}<inlineCode parentName="li">{`ApiDataBinding[]`}</inlineCode>{` added to the property key.`}</li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Function`}</strong>{` Function to wrap your component. This higher order component adds a property for each binding, as defined in the bindings param of the HOC, to the wrapped component and an additional apiDataActions property with type `}<a parentName="p" {...{
        "href": "#actions"
      }}>{`Actions`}</a>{`.`}</p>
    <h2 {...{
      "id": "types-and-interfaces"
    }}>{`Types and interfaces`}</h2>
    <p>{`These can be used for documentation purposes, but are also available as TypeScript and Flow types and can be imported
straight from the react-api-data package.`}</p>
    <h3 {...{
      "id": "networkstatus"
    }}><inlineCode parentName="h3">{`NetworkStatus`}</inlineCode></h3>
    <p>{`Type: `}<strong parentName="p">{`String enum`}</strong>{` `}</p>
    <p><strong parentName="p">{`Possible values`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`"ready"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"loading"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"failed"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`"success"`}</inlineCode></li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "apidatabinding"
    }}><inlineCode parentName="h3">{`ApiDataBinding`}</inlineCode></h3>
    <p>{` Represents an endpoint `}<em parentName="p">{`call`}</em>{`. `}</p>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`data`}</inlineCode>{` `}<strong parentName="li">{`any`}</strong>{` The result data of your request, or undefined if your request has not completed or has no response body.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`request`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#apidatarequest"
          }}>{`ApiDataRequest`}</a></strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`perform`}</inlineCode>{` `}<strong parentName="li">{`(params?: `}<a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a>{`, body?: any) => `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
          }}>{`Promise`}</a>{`<`}<a parentName="strong" {...{
            "href": "#apidatabinding"
          }}>{`ApiDataBinding`}</a>{`>`}</strong>{`
Manually trigger a call to the endpoint. The params parameter is merged with the params parameter of the binding. Returns a promise that resolves with an ApiDataBinding after call has completed. Use request networkStatus to see if call was succeeded or not. Both the original ApiDataBinding and the resolved promise contain the result of the performed request.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`invalidateCache`}</inlineCode>{` `}<strong parentName="li">{`() => Promise`}{`<`}{`void>`}</strong>{` Manually trigger a cache invalidation of the endpoint with the current parameters.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`getInstance`}</inlineCode>{` `}<strong parentName="li">{`(instanceId: string) => `}<a parentName="strong" {...{
            "href": "#apidatabinding"
          }}>{`ApiDataBinding`}</a></strong>{` get an independent instance of this binding. This enables you to make multiple (possibly parallel) calls to the same endpoint while maintaining access to all of them.`}</li>
    </ul>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`type Props = {
 users: ApiDataBinding<Array<User>>
}
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "actions"
    }}><inlineCode parentName="h3">{`Actions`}</inlineCode></h3>
    <p>{`Perform actions on any configured endpoint. These actions do not need to be dispatched.`}</p>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`perform`}</inlineCode>{` `}<strong parentName="li">{`(`}<inlineCode parentName="strong">{`endpointKey`}</inlineCode>{` string, `}<inlineCode parentName="strong">{`params?`}</inlineCode>{` `}<a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a>{`, `}<inlineCode parentName="strong">{`body?`}</inlineCode>{` any, `}<inlineCode parentName="strong">{`instanceId?`}</inlineCode>{` string) => `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
          }}>{`Promise`}</a>{`<`}<a parentName="strong" {...{
            "href": "#apidatabinding"
          }}>{`ApiDataBinding`}</a>{`>`}</strong>{` Manually trigger an request to an endpoint. Primarily used for any non-GET requests. For GET requests it is preferred to use `}<a parentName="li" {...{
          "href": "#withapidata"
        }}>{`withApiData`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`invalidateCache`}</inlineCode>{` `}<strong parentName="li">{`(`}<inlineCode parentName="strong">{`endpointKey`}</inlineCode>{` string, `}<inlineCode parentName="strong">{`params?`}</inlineCode>{` `}<a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a>{`, `}<inlineCode parentName="strong">{`instanceId?`}</inlineCode>{` string) => void`}</strong>{` Invalidates the result of a request, settings it's status back to 'ready'. Use for example after a POST, to invalidate`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`purgeAll()`}</inlineCode>{` `}<strong parentName="li">{`() => void`}</strong>{` Clears the whole apiData redux store. Might be useful fore logout functions.
a GET list request, which might need to include the newly created entity.`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "apidatarequest"
    }}><inlineCode parentName="h3">{`ApiDataRequest`}</inlineCode></h3>
    <p>{`Information about a request made to an endpoint.`}</p>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`result?`}</inlineCode>{` `}<strong parentName="li">{`any`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`networkStatus`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#networkstatus"
          }}>{`NetworkStatus`}</a></strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lastCall`}</inlineCode>{` `}<strong parentName="li">{`number`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`duration`}</inlineCode>{` `}<strong parentName="li">{`number`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`response?`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a></strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`errorBody?`}</inlineCode>{` `}<strong parentName="li">{`any`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`endpointKey`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`params?`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "#endpointparams"
          }}>{`EndpointParams`}</a></strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`url`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong>{` `}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "endpointparams"
    }}><inlineCode parentName="h3">{`EndpointParams`}</inlineCode></h3>
    <p>{`Map of parameter names to values.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "apidataendpointconfig"
    }}><inlineCode parentName="h3">{`ApiDataEndpointConfig`}</inlineCode></h3>
    <p>{` Specification and configuration of an endpoint.`}</p>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`url`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`method`}</inlineCode>{` `}<strong parentName="li"><inlineCode parentName="strong">{`"GET"`}</inlineCode>{` | `}<inlineCode parentName="strong">{`"POST"`}</inlineCode>{` | `}<inlineCode parentName="strong">{`"PUT"`}</inlineCode>{` | `}<inlineCode parentName="strong">{`"PATCH"`}</inlineCode>{` | `}<inlineCode parentName="strong">{`"DELETE"`}</inlineCode></strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cacheDuration?`}</inlineCode>{` `}<strong parentName="li">{`number`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`responseSchema?`}</inlineCode>{` `}<strong parentName="li">{`Object | Array`}{`<`}{`Object>`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`transformResponseBody?`}</inlineCode>{` `}<strong parentName="li">{`function (responseBody: Object): NormalizedData`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`beforeSuccess?`}</inlineCode>{` `}<strong parentName="li">{`function (handledResponse: {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, body: any}, beforeProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigbeforeprops"
          }}>{`ApiDataConfigBeforeProps`}</a>{`): {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}`}</strong>{`
Callback function that allows you to alter a response before it gets processed and stored. Can also be used to validate a response and turn it into a failed request by setting the `}<inlineCode parentName="li">{`ok`}</inlineCode>{` property of the response to false.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`beforeFailed?`}</inlineCode>{` `}<strong parentName="li">{`function (handledResponse: {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}, beforeProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigbeforeprops"
          }}>{`ApiDataConfigBeforeProps`}</a>{`): {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}`}</strong>{`
Callback function that allows you to alter a response before it gets processed and stored. Can also be used to turn it into a successful request by setting the `}<inlineCode parentName="li">{`ok`}</inlineCode>{` property of the response to true.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`afterSuccess`}</inlineCode>{` `}<strong parentName="li">{`function (afterProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigafterprops"
          }}>{`ApiDataConfigAfterProps`}</a>{`): boolean | void`}</strong>{`
Callback for executing side-effects when a call to this endpoint results in a "success" networkStatus. Called directly after the state is updated. If set, afterSuccess in globalConfig gets called after this, unless `}<inlineCode parentName="li">{`false`}</inlineCode>{` is returned.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`afterFailed`}</inlineCode>{` `}<strong parentName="li">{`function (afterProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigafterprops"
          }}>{`ApiDataConfigAfterProps`}</a>{`): boolean | void`}</strong>{`
Callback for executing side-effects when a call to this endpoint results in a "failed" networkStatus. Called directly after the state is updated. If set, afterFailed in globalConfig gets called after this, unless `}<inlineCode parentName="li">{`false`}</inlineCode>{` is returned.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`setHeaders?`}</inlineCode>{` `}<strong parentName="li">{`function (defaultHeaders: Object, state: Object): Object`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`setRequestProperties?`}</inlineCode>{` `}<strong parentName="li">{`function (defaultProperties: Object, state: Object): Object`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`timeout?`}</inlineCode>{` `}<strong parentName="li">{`number`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`autoTrigger?`}</inlineCode>{` `}<strong parentName="li">{`boolean`}</strong>{`
Trigger calls automatically if needed. Defaults to `}<inlineCode parentName="li">{`true`}</inlineCode>{` for GET requests and `}<inlineCode parentName="li">{`false`}</inlineCode>{` for all other requests.`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "apidataglobalconfig"
    }}><inlineCode parentName="h3">{`ApiDataGlobalConfig`}</inlineCode></h3>
    <p>{` Global configuration for all endpoints.`}</p>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`setHeaders?`}</inlineCode>{` `}<strong parentName="li">{`function (defaultHeaders: Object, state: Object): Object`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`setRequestProperties`}</inlineCode>{` `}<strong parentName="li">{`function (defaultProperties: Object, state: Object): Object`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`beforeSuccess?`}</inlineCode>{` `}<strong parentName="li">{`function ({response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, body: any}, beforeProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigbeforeprops"
          }}>{`ApiDataConfigBeforeProps`}</a>{`): {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`beforeFailed?`}</inlineCode>{` `}<strong parentName="li">{`function ({response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}, beforeProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigbeforeprops"
          }}>{`ApiDataConfigBeforeProps`}</a>{`): {response: `}<a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a>{`, responseBody: any}`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`afterSuccess?`}</inlineCode>{` `}<strong parentName="li">{`function (afterProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigafterprops"
          }}>{`ApiDataConfigAfterProps`}</a>{`): void`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`afterFailed?`}</inlineCode>{` `}<strong parentName="li">{`function (afterProps: `}<a parentName="strong" {...{
            "href": "#apidataconfigafterprops"
          }}>{`ApiDataConfigAfterProps`}</a>{`): void`}</strong>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`timeout?`}</inlineCode>{` `}<strong parentName="li">{`number`}</strong>{` `}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "apidataconfigbeforeprops"
    }}><inlineCode parentName="h3">{`ApiDataConfigBeforeProps`}</inlineCode></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`endpointKey`}</inlineCode>{` `}<strong parentName="p">{`string`}</strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`request`}</inlineCode>{` `}<strong parentName="p"><a parentName="strong" {...{
              "href": "#apidatarequest"
            }}>{`ApiDataRequest`}</a></strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`requestBody?`}</inlineCode>{` `}<strong parentName="p">{`any`}</strong>{` `}</p>
        <hr parentName="li"></hr>
        <h3 parentName="li" {...{
          "id": "apidataconfigafterprops"
        }}><inlineCode parentName="h3">{`ApiDataConfigAfterProps`}</inlineCode></h3>
        <p parentName="li"><strong parentName="p">{`Properties`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`endpointKey`}</inlineCode>{` `}<strong parentName="p">{`string`}</strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`request`}</inlineCode>{` `}<strong parentName="p"><a parentName="strong" {...{
              "href": "#apidatarequest"
            }}>{`ApiDataRequest`}</a></strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`requestBody?`}</inlineCode>{` `}<strong parentName="p">{`any`}</strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`resultData`}</inlineCode>{` `}<strong parentName="p">{`any`}</strong>{` `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`dispatch`}</inlineCode>{` `}<strong parentName="p">{`Function`}</strong>{`
Redux' dispatch function. Useful for state related side effects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`getState`}</inlineCode>{` `}<strong parentName="p">{`Function`}</strong>{`
Get access to your redux state.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`actions`}</inlineCode>{` `}<strong parentName="p"><a parentName="strong" {...{
              "href": "#actions"
            }}>{`Actions`}</a></strong>{`
Perform actions on any configured endpoint`}</p>
      </li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "apidatastate"
    }}><inlineCode parentName="h3">{`ApiDataState`}</inlineCode></h3>
    <p>{`Type of the Api-data state`}</p>
    <hr></hr>
    <h3 {...{
      "id": "requesthandler"
    }}><inlineCode parentName="h3">{`RequestHandler`}</inlineCode></h3>
    <p>{`Type: `}<strong parentName="p">{`Function`}</strong></p>
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`url`}</inlineCode>{` `}<strong parentName="li">{`string`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`requestProperties`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters"
          }}>{`RequestInit`}</a></strong>{`
The `}<inlineCode parentName="li">{`init`}</inlineCode>{` parameter for fetch.`}</li>
    </ul>
    <p><strong parentName="p">{`Returns`}</strong></p>
    <p><strong parentName="p">{`Promise`}{`<`}<a parentName="strong" {...{
          "href": "#handledresponse"
        }}>{`HandledResponse`}</a>{`>`}</strong></p>
    <hr></hr>
    <h3 {...{
      "id": "handledresponse"
    }}><inlineCode parentName="h3">{`HandledResponse`}</inlineCode></h3>
    <p><strong parentName="p">{`Properties`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`response`}</inlineCode>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
          }}>{`Response`}</a></strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{` `}<strong parentName="li">{`any`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      